import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Next on Stage",
        subtitle: "I K!lled my Mother'",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702424/xdvlhckzk5baqp6swhtw.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "i-killed-my-mother-it-wasnt-my-fault-social-theatre-festival-antisocial-iehpl"
        }
    },
    {
        title: "Techno Party",
        subtitle: "Stella Bossi. Nov 10th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702460/ygcme2gfy0hdarsnwdyn.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "souldout-present-stella-bossi-live-in-mumbai-nov10-2024"
        }
    },
    {
        title: "October Edition",
        subtitle: "Lil Flea, BIG Celebration",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702480/zrhozrj5agru7fayflux.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "the-lil-flea-mumbai-2024"
        }
    },
    //Delhi
    {
        title: "DEL Comic Con",
        subtitle: "Pop Culture Showcase",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702618/s9igtvwc2s25xsc9rsx6.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "delhi-comic-con-2024-dec6-2024"
        }
    },
    {
        title: "Not Just Gin!",
        subtitle: "The Explorer’s Club",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702651/mezpgaxlefqipci4aohu.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "explorers-club-delhi-ncr"
        }
    },
    {
        title: "13th Edition",
        subtitle: "Horn Ok Please Food Fest",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703828/ydv54k1hid94jesmrcr8.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "horn-ok-please-the-happiest-food-festival-13-nov16-2024"
        }
    },
    //Bengaluru
    {
        title: "Sunday Soul Sante",
        subtitle: "Diwali Edit - Oct 27th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052608/zzapfsvjsdua3ur6b1ie.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-diwali-edition-oct27-2024"
        }
    },
    {
        title: "BLR Comic Con",
        subtitle: "Pop Culture Showcase",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703930/wywvfqsph7kvvjgbf717.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "bengaluru-comic-con-jan18-2025"
        }
    },
    {
        title: "Octobeer Fest",
        subtitle: "For the 'Good Times'",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703956/sujjqmwwn8cerrfpmljj.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "kingfisher-octobeer-fest-sep27-2024"
        }
    },
    //Pune
    {
        title: "DinoVerse",
        subtitle: "The Dino's are back!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704018/ampmc4cktbjdo8mobidt.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "dinoverse-an-epic-adventure-at-nexus-westen-mall-oct3-2024"
        }
    },
    {
        title: "Indie Sensation",
        subtitle: "Shalmali Kholgade, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052849/ebxhmag0rrtyhtyhelqw.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "this-is-me-india-tour-pune-oct18-2024"
        }
    },
    {
        title: "Taste of Bavaria",
        subtitle: "Pune Oktoberfest",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052888/i4qkphckaz1nnjldlngx.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "pune-oktoberfest-2024-nov9-2024"
        }
    },
    //Goa
    {
        title: "No EDM, No Party",
        subtitle: "Raeeth, Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699563/qx8djyzerxqedakh6nxc.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-experience-31-dec-dec31-2024"
        }
    },
    {
        title: "3 day wellness fest",
        subtitle: "In Goa this November",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066746/pzjvpivvopkn1eyfw4tj.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "bliss-tree-experience-goa-2024-nov7-2024"
        }
    },
    {
        title: "A Billion streams",
        subtitle: "Hugel in Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704138/lgdbrgnxsmmjrzcqdnbc.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-hugel-8-nov-nov8-2024"
        }
    },
    //Hyderabad
    {
        title: "Double Treat",
        subtitle: "Dreamhack x Comic Con",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812939/fhsxpk4ikpqisxicfyr8.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    },
    {
        title: "Shop till you drop",
        subtitle: "Sunday Soul Sante",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704175/kxw7fcbzabguzhgjeysf.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-hyderabad-17th-edition-nov10-2024"
        }
    },
    {
        title: "No EDM, No Party",
        subtitle: "Xora on Nov 24th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699679/snatgapmwihlpnxfwkcc.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "techno-and-chill-w-rivo-at-xora-nov24-2024"
        }
    },
    //Chennai
    {
        title: "Karthik Raaja",
        subtitle: "LIVE on Oct 19th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726904591/yj4ozrbfn2dhl3l1gey3.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "king-of-kings-2024-karthik-raaja-live-in-concert-coimbatore-oct19-2024"
        }
    },
    {
        title: " In'tl Film Festival",
        subtitle: "200 films, 50 countries",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704222/hniatjb5jab4o4ggmpvv.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "chennai-international-film-festival-annual-festival-pass-oct1-2024"
        }
    },
    {
        title: "Indie Music Fest",
        subtitle: "From Nov 22nd to 24th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704243/rsnlabh3gfzcxmz0pgau.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "international-independent-music-festival-iimf-2024-nov22-2024"
        }
    },
    //Kolkata
    {
        title: "Ek Mulaqaat",
        subtitle: "Vishal Mishra, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053058/j5v9kq1wtkxu7rk3ocm7.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "vishal-mishra-kolkata-oct19-2024"
        }
    },
    {
        title: "Mann Bharrya",
        subtitle: "B Praak, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053082/fch569lkhvr8ggfpecsy.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "kolkata-odyssey-i-b-praak-oct20-2024"
        }
    },
    {
        title: "It's a 'Mehfil'",
        subtitle: "Bismil Ke Mehfil ",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704307/iik0biqehnzcn18smnj7.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "bismil-ke-mehfil-dec28-2024"
        }
    },
    //coimbatore
    {
        title: "Vidyasagar, LIVE",
        subtitle: "Greatest hits on Oct 26th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053129/cd2akql1qrmim4xzlkrg.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "vidyasagar-the-greatest-hits-live-bengaluru-oct26-2024"
        }
    },
    {
        title: "Sonu Nigam, LIVE",
        subtitle: "On November 30th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704460/cbuvqikiflbknngqccyx.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        }
    },
    {
        title: "Vroom, vroom!",
        subtitle: "Indian Racing Fest R4",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704486/tgcjofhwmgdtrcjktljg.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "indian-racing-festival-2024-round-4-kari-motor-speedway"
        }
    },
    //visakhapatnam
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Dreamhack x CominCon",
        subtitle: "Best of both worlds",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726905193/zbpkf3s5t8a2yiwunfmt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    },
    //vizag
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Dreamhack x CominCon",
        subtitle: "Best of both worlds",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726905193/zbpkf3s5t8a2yiwunfmt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    }
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702534/vcglpced4d49bvsshxj1.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702529/v0xbewtc0axbafnpzx9w.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "gully-fest-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703868/e64tl9kluufwnyg3ojpg.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703865/qxcgylvrdfpuyinc9t1r.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-delhi-dec22-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052707/xe1evgjoihoviegjqbic.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052702/szcvt64ilbkn0ivbfuay.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "vidyasagar-the-greatest-hits-live-bengaluru-oct26-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704043/gmx3tjbcvfbphtvmmi1o.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704046/gwpmrwsozyrqao34tpah.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "jameson-connects-pune-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052928/aeqpft0vebwm1ubs46s7.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052931/eamrh5zlpfofyofi5gah.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-pretty-pink-16-nov-nov16-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722743272/jraxkrnotsxdeplez9bz.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722743278/xdhxhiigjn1traucrgey.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704265/q2to3ri6hupywvy6zdra.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704268/n2cxd61spyqs7ibbtwab.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "sonu-nigam-live-in-chennai-nov30-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704512/awvnkv8ffiu5qys7hyn2.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704515/wishnm1nwcbbnznhkyfa.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "king-of-kings-2024-karthik-raaja-live-in-concert-coimbatore-oct19-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704363/ibxgfit8g3jchncjkffd.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704379/grcvumzdm39xoqk87row.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "republic-of-rock-2024-i-kolkata-nov9-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039161/wieerk3ojaqv5auxigy6.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039166/ccvsttnb57ddne4x0bju.jpg",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    }
];
export const showcase = [
    //Type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052568/djrcbjtk5zfnbipdahnk.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052565/owaekox2trjpcpci66of.jpg",
        //categoryIcon: <Theatre />,
        heading: "The Power of Vulnerability",
        subheading: "Kiran Nadar Museum of Art (KNMA) Theatre Festival",
        caption: "KMNA's first theatre festival, featuring explorations in contemporary theatre will take place from Oct 14th to 20th.",
        cta: "Discover more",
        includeCities: ["delhi"],
        cardListType: "small-event-card",
        tag: "kiran-nair-theatre-festival-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "article",
            slug: "kiran-nadar-museum-of-art-theatre-festival"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703987/gfac98re6ce5jxypfwop.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703982/mcu7jtu4cewadeo0mvsd.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Comic Con 2024",
        subheading: "The greatest pop-culture extravaganza",
        caption: "An epic showcase of comics, cosplay, collectible merch, toys, immersive fan experiences, gaming, and more await. Get Your Tickets!",
        cta: "Discover more",
        includeCities: ["bengaluru"],
        cardListType: "small-event-card",
        tag: "comic-con-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/comic-con-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703987/gfac98re6ce5jxypfwop.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703982/mcu7jtu4cewadeo0mvsd.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Comic Con 2024",
        subheading: "The greatest pop-culture extravaganza",
        caption: "An epic showcase of comics, cosplay, collectible merch, toys, immersive fan experiences, gaming, and more await. Get Your Tickets!",
        cta: "Discover more",
        includeCities: ["hyderabad"],
        cardListType: "small-event-card",
        tag: "comic-con-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/comic-con-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["ahmedabad"],
        cardListType: "small-event-card",
        tag: "navratri2024-ahmedabad",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-ahmedabad"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["surat"],
        cardListType: "small-event-card",
        tag: "navratri2024-surat",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-surat"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    // Type B
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702577/ol2fksletcctodhirwyg.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728702574/zotx4b5cwbdytmcccrtp.jpg",
        categoryIcon: <Music />,
        heading: "Innellea India Tour",
        subheading: "A captivating fusion of melancholic soundscapes & driving rhythms awaits!",
        caption: "Influenced by melodic trends in electronic music, Innellea produces a unique & abstract sound and is known for massive releases like 'Innervisions'.",
        cta: "Book Now",
        includeCities: ["mumbai"],
        cardListType: "schedule-card",
        tag: "innellea-the-belonging-tour-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "go",
            slug: "innellea-the-belonging-tour-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["goa"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["pune"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704425/eybpd6wbpfzwbtzvvxno.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704421/ffyd2mirdeonzyvwkzl6.jpg",
        categoryIcon: <Music />,
        heading: "First time in Kolkata",
        subheading: "Sunidhi's I Am Home Concert",
        caption: "Known for her vocal range & versatility, Sunidhi Chauhan has recorded songs for films in several Indian languages and is the recipient of many awards for the same.",
        cta: "Buy Now",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "sunidhis-i-am-home-i-kolkata-dec24-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699990/fv1kl34fdfjfyicqeyw6.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699956/dquxcaxfazkog2fyznjr.jpg",
        categoryIcon: <Music />,
        heading: "Hip-hop Adhi LIVE!",
        subheading: "Experience HIPHOP TAMIZHA Live in Chennai!",
        caption: "Tamil folk arts seamlessly blended into high energy performances and music. Are you ready for an  unforgettable spectacle?",
        cta: "Buy Now",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "return-of-the-dragon-machi-home-edition-oct19-2024-chennai"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284999/yjyuwel1lj6l9qsxs0ui.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285003/bhhsthkhycasjqh28wyn.jpg",
        categoryIcon: <Music />,
        heading: "Yuvan Shankar LIVE!",
        subheading: "Experience Yuvan Shankar Raja Live: The G.O.A.T. in Coimbatore!",
        caption: "Join us in Coimbatore for an unforgettable night with Yuvan Shankar Raja, the G.O.A.T. of music. Live performance, exceptional vibes!",
        cta: "Buy Now",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225442/hy0evrvrfjqtpjcst8na.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225439/fijvxhp4z4s7gsrtaeo2.jpg",
        categoryIcon: <Music />,
        heading: "First time in Vizag!",
        subheading: "Catch Armaan Malik LIVE this November!",
        caption: "Known for his soulful voice and chart-topping hits - from romantic ballads to foot-tapping numbers, experience the magic of his music up close and personal.",
        cta: "Buy Now",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];
